import React from "react";
import styled from "styled-components";

export default function RowOrderService({item,order}) {
    return (
      <RLEquals
      item={item}
        order={order}>
        <h1 className="font30 extraBold" style={{ padding:"0px 0px 0px 0px"}}>{item.title}</h1><br/>
               <p className="font16" style={{ "text-align": "justify" }}>
               {item.body} 
            </p>
      </RLEquals>
    );
  }


  const RLEquals = styled.div`
  width: 50%;
  p {
    max-width: 600px;
  }
  order:  ${(props) => (props.order)};
  padding: 0px ${(props) => (props.order =="1"?"50px":"0")} 0px ${(props) => (props.order =="2"?"50px":"0")};
  text-align: center;
  @media (max-width: 860px) {
    padding: 0px 0px 0px 0px;
    width: 100%;
    order:  ${(props) => (props.item.orderContent)};
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;