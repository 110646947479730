import React from "react";

import TopNavbarSocial from "../components/Nav/TopNavbarSocial";
import SocialC from "../components/Sections/SocialC";

// Sections
import { app } from "./../firebaseConfig";

export default function Social() {
  app.analytics().logEvent("Social_Init", {}); 
  return (
    <>
    <TopNavbarSocial/>
   <SocialC/>

    </>
  );
}
