import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation } from 'react-router-dom';
// Components
import { DecryptValue } from "../utils/Encrypt";
import { getUser } from "../service/UserService";
import Icon192 from "../assets/img/Icon-192.png"



export default function Users() {
  const [user,setUSer] = useState(null)
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');

  useEffect(()=>{
  fetchUser()
  },[])

  const fetchUser = async() =>{
    const id_ = DecryptValue(id)
    console.log("ID: ",id_);
   const response = await getUser(id_)
   if (!response.error){
    console.log(response.result)
     setUSer(response.result)
   }
  }

  return (
    <Container>

   
    <ProfileCard>
      <Header>
        <Avatar src={Icon192} alt="Profile Avatar" />
      </Header>
      <UserInfo>
        <Name>{user?.name} {user?.full_names}</Name>

       <Tag>{user?.type}</Tag>
      </UserInfo>
   
      
      </ProfileCard>
      <ProfileCard>
      <InfoSection>
        <h3>Información</h3>
        <InfoItem>
          <span>ID:</span>
          <span>G-{user?.id_number}</span>
        </InfoItem>
        <InfoItem>
          <span>Tipo de evento:</span>
          <span>{user?.event?.type_event}</span>
        </InfoItem>
        <InfoItem>
          <span>Descripción del evento:</span>
          <span>{user?.event?.short_desc}</span>
        </InfoItem>
        <InfoItem>
          <span>Fecha del evento:</span>
          <span>26 March, 2023</span>
        </InfoItem>
      </InfoSection>
    
      </ProfileCard>
       </Container>)



}




 const Container = styled.div`
 display: flex;
  align-items: center;
  margin: 0;
  height: 100vh;
    flex-direction: column;
   background-color: #f0f2f5; /* Fondo gris claro */
 `;


const ProfileCard = styled.div`

 width: 600px;
 margin-top: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  font-family: Arial, sans-serif;
     @media (max-width: 768px) {
     width: 95%;
  }
`

const Header = styled.div`
  background: url('https://placehold.co/400x100') no-repeat center center;
  background-size: cover;
  height: 100px;
  position: relative;
`

const Avatar = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 4px solid #fff;
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
`

const UserInfo = styled.div`
  text-align: center;
  padding: 50px 20px 20px;
`

const Name = styled.h2`
margin-bottom: 20px;

  font-size: 1.5em;
`

const Location = styled.p`
  color: #888;
  margin: 5px 0;
`

const JoinedDate = styled.p`
  color: #888;
  margin: 5px 0;
`

const Bio = styled.p`
  text-align: center;
  padding: 0 20px;
  color: #555;
`

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 20px 0;
`

const Button = styled.button`
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
`

const InfoSection = styled.div`
  padding: 20px;
  border-top: 1px solid #eee;
`

const InfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
`

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 20px;
`

const Tag = styled.span`
  background-color: orange;
  color: white;
  font-weight: 600;
  border-radius: 5px;
  padding: 5px 10px;
`