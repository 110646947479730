import React from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Ours from "../components/Sections/Ours";
import Blog from "../components/Sections/SocialC";
import Pricing from "../components/Sections/Pricing";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer";
import { app } from "./../firebaseConfig";

export default function Landing() {
  app.analytics().logEvent("Site_Init", {}); 
  return (
    <>
    <TopNavbar />
      <Header />
      <Services />
      <Ours />
      <Contact />
      <Footer />
    </>
  );
}
