import React, { Component } from "react";
import ProjectBox from "../../Elements/ProjectBox";
import ClientLogo01 from "../../../assets/img/clients/logo01.svg";
import ClientLogo02 from "../../../assets/img/clients/logo02.svg";
import ClientLogo03 from "../../../assets/img/clients/logo03.svg";
import ProjectImg5 from "../../../assets/img/projects/5.png";

import Ambulancia from "../../../assets/img/services/agreganos.png";
import APH from "../../../assets/img/services/Aph.png";
import MedicoEnCasa from "../../../assets/img/services/consulta_medica.png";
import TrasladoUrg from "../../../assets/img/services/traslados_urgencias.png";
import TraaladoPro from "../../../assets/img/services/traslado_alta.png";
import Eventos from "../../../assets/img/services/eventos.png";
import { app } from "../../../firebaseConfig";
class ImageListService extends Component {

  eventSave = (val) => {
    app.analytics().logEvent(val, {});
  };


  state = {
    listitems: [
      { index: 0, title: "Nuestros Servicios", img: Ambulancia, body: "dsdsdssd" },
      { index: 1, title: "Aph", img: APH, body: "dsdsdssd" },
      {
        index: 2,
        title: "Medico en Casa",
        img: MedicoEnCasa,
        body: "dsdsdssd",
      },
      { index: 3, title: "Traslados Criticos", img: TrasladoUrg, body: "dsdsdssd" },
      { index: 4, title: "Traslados Estables", img: TraaladoPro, body: "dsdsdssd" },
      { index: 5, title: "Eventos", img: Eventos, body: "dsdsdssd" },
    ],
  };
  render() {
    return (
      <React.Fragment>
        {this.state.listitems.map((val) => (
          <div key={val.index} className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
            <ProjectBox  img={val.img} action={() => this.eventSave("Site_"+val.title)} />{" "}
          </div>
        ))}
      </React.Fragment>
    );
  }
}
export default ImageListService;
