import React from "react";
import styled from "styled-components";
import RowSocial from "./Contact/rowSocial";

export default function SocialC() {
  return (
    <Wrapper id="blog">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">
              Servicios de Ambulancia 24/7
            </h1>
          </HeaderInfo>
          <Container>
          <RowSocial
              txtColor={"#323232"}
              color={"#F3F3F3"}
              tag={"Social_"}
            />
          </Container>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 100px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
    h1{
      font-size: 1.2rem;
    }
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center; // 1
  flex-flow: column wrap; // 2
  width: 100%;
  height: 100%;
  @media (max-width: 860px) {
  }
`;

const List = styled.div`
  display: flex;
  justify-content: center; // 3
  flex-flow: row wrap; // 4
`;

const Card = styled.div`
  margin: 20px;
  background: #fff;
  height: 80px;
  width: 400px;
  border-radius: 25px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-flow: column; // 5
  justify-content: center;
  align-items: center;
  @media (max-width: 860px) {
    width: 100%;
  }
`;
