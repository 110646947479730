import styled from "styled-components";
export default function RowClickSocial({ item, action,color,txtColor="#000000" }) {
    return (
    <ImgBtn
      className="aniamte pointer"
      onClick={action ? () => action() : null}
      color={color}>
      <Wrapper txtColor={txtColor}>
        <img
          className="icon"
          src={item.img}
          alt=""
          style={{ height: "30px", width: "30px", margin: "0px 10px" }}
        />
        <span className="font18 semiBold"> {item.title} </span>
      </Wrapper>
    </ImgBtn>
  );
}

const Wrapper = styled.div`
  color: #fcfcfc;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: row;

  span {
    margin: 15px 15px 15px 10px;
    color:${(props)=> props.txtColor };
  }
  @media (max-width: 600px) {
    justify-content: left;
    width: 100%;
  }
`;

const ImgBtn = styled.button`
  border-radius: 25px;
  background-color: ${(props)=> props.color};
  border: 0px;
  outline: none;
  margin: 0px 0px 20px 0px;
  :hover > div {
    opacity: 0.5;
  }
  @media (max-width: 500px) {
    padding: 0px 20px;
    justify-content: left;
    width: 100%;
  }
  margin-right: 15px;
`;
