import React, { Component } from "react";
import RowOrderService from "./orderRow";
import OrderRowR from "./orderRowR";
import styled from "styled-components";
import ClientLogo01 from "../../../assets/img/clients/logo01.svg";
import ClientLogo02 from "../../../assets/img/clients/logo02.svg";
import ClientLogo03 from "../../../assets/img/clients/logo03.svg";
import MedicoDom from "../../../assets/img/s_medico_domicilio.png";
import TrasladoUrg from "../../../assets/img/s_traslado_urg.png";
import TrasladoPro from "../../../assets/img/s_traslado_pro.png";
import Eventos from "../../../assets/img/s_eventos.png";

class RowService extends Component {
  state = {
    listitems: [
      {
        index: 0,
        orderImg: "2",
        orderContent: "1",
        title: "Médico a Domicilio",
        img: MedicoDom,
        body: "En APH Medik porque tu salud es importante; realizamos servicios personalizado en la comodidad de tu hogar, contamos con medidas de higiene y confort completas, con un equipo multidisciplinario de profesionales con calidez humana, brindando un adecuado diagnóstico y tratamiento de patologías comunes.",
      },
      {
        index: 1,
        orderImg: "1",
        orderContent: "2",
        title: "Traslado de Paciente en Etapa aguda o critica",
        img: TrasladoUrg,
        body: "En APH Medik ofrecemos servicio de emergencia y rescate, durante el traslado se realiza una serie de actividades médicas de reanimación y/o soporte que requieren capacitación específica al respecto, con personal profesional y calificado a pacientes en circunstancias críticas, con lo cual se logra una mejor condición de ingreso del paciente al hospital y por tanto mayor sobrevida frente a la causa del evento urgente.",
      },
      {
        index: 2,
        orderImg: "2",
        orderContent: "1",
        title: "Traslado de pacientes Estables",
        img: TrasladoPro,
        body: "En APH Medik ofrecemos traslados a pacientes con necesidades especiales; tales como, pacientes que se encuentren en circunstancias que les imposibilite cualquier tipo de movimiento o estén en cama, mediante la cual los familiares podrán gestionar chequeos, exámenes médicos del paciente, o viajes a otra ciudad, durante este trayecto se brindara la asistencia optima y oportuna en cuanto al servicio prestado.",
      },
      {
        index: 3,
        orderImg: "1",
        orderContent: "2",
        title: "Acompañamiento a Eventos",
        img: Eventos,
        body: "En APH Medik prestamos servicio con asistencia médica, durante el evento, aportando atención oportuna y con una sólida respuesta profesional con el paciente.\nContamos con personal debidamente capacitado para brindar la cobertura a todo tipo de eventos:",
      },
    ],
  };
  render() {
    return (
      <React.Fragment>
        <ul className="list-group  container">
          {" "}
          {this.state.listitems.map((val) => (
            <Advertising key={val.index} className="flexSpaceCenter">
              <RowOrderService
                item={val}
                order={val.orderImg}
              ></RowOrderService>{" "}
              <OrderRowR item={val} order={val.orderContent}>
                {" "}
              </OrderRowR>{" "}
            </Advertising>
          ))}{" "}
        </ul>{" "}
      </React.Fragment>
    );
  }
}
export default RowService;

const Advertising = styled.div`
  margin: 0px 0px 70px 0;
  padding: 0px;
  position: relative;
  @media (max-width: 1160px) {
    padding: 10px 0 0px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 50px 0;
    margin: 0px 0 0px 0;
  }
`;
