const CryptoJS = require('crypto-js');

const secretKey = '#A#P#H#MED-KEY';


// Función para codificar en Base64 seguro para URL usando crypto-js
const toUrlSafeBase64 = (str) => { 
    return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(str)).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
  };
  
  // Función para decodificar desde Base64 seguro para URL usando crypto-js
  const fromUrlSafeBase64 = (str) => {
    let base64 = str.replace(/-/g, '+').replace(/_/g, '/');
    while (base64.length % 4) {
      base64 += '=';
    }
    return CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(base64));
  };

  export function EncryptValue(parameter) {
    const encryptedValue = CryptoJS.AES.encrypt(parameter, secretKey).toString();
    return toUrlSafeBase64(encryptedValue);
  }
  
  // Función para desencriptar
  export function DecryptValue(encryptedParameter) {
    // Decodificar el valor en Base64 seguro para URL
    const decodedBase64Value = fromUrlSafeBase64(encryptedParameter);
    // Desencriptar el valor
    const bytes = CryptoJS.AES.decrypt(decodedBase64Value, secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  }