import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import RowEvent from "../Sections/services/rowEvents";
import RowService from "../Sections/services/rowService";
import { app } from "../../../src/firebaseConfig";
// Components
import ClientSlider from "../Elements/ClientSlider";

import FullButton from "../Buttons/FullButton";
import ImageListService from "./services/ImageListService";
// Assets
import AddImage1 from "../../assets/img/add/1.png";
import AddImage2 from "../../assets/img/add/2.png";
import AddImage3 from "../../assets/img/add/3.png";
import AddImage4 from "../../assets/img/add/4.png";
import BannerBottom from "../../assets/img/BannerHeader2.png";

import M1 from "../../assets/img/medicHome/m1.png";
import M2 from "../../assets/img/medicHome/m2-02.png";
import M3 from "../../assets/img/medicHome/m3-04.png";
import M4 from "../../assets/img/medicHome/m4-03.png";

import a1 from "../../assets/img/ambulance/a1.png";
import a2 from "../../assets/img/ambulance/a2-08.png";
import a3 from "../../assets/img/ambulance/a3-07.png";
import a4 from "../../assets/img/ambulance/a4-05.png";


export default function Services() {
  const eventSave = (val) => {
    app.analytics().logEvent(val, {});
  };

  return (
    <Wrapper id="service">
      <br />
      <AddImgWrapp>
<img className="" src={BannerBottom} alt="Service" ></img>
</AddImgWrapp>
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold"> Nuestros Servicios </h1>{" "}
            <p className="font16">
              APH Medik se caracteriza por brindarte un servicio de asistencia
              médica de calidad <br /> y estar siempre alerta 24 / 7 para
              ayudarte en tu emergencia.{" "}
            </p>{" "}
          </HeaderInfo>{" "}
          <br />
          <br />
          <br />
          <RowService />
        </div>{" "}
        {/* <div className="lightBg">
          <div className="container">
            <br />
            <br />
            <h1 className="font25 extraBold">
              {" "}
              APH Medik cuida de ti en todo tipo de eventos{" "}
            </h1>{" "}
            <RowEvent />
          </div>{" "}
        </div> */}
        <div className="" style={{ padding: "60px 0px 50px 0px" }}>
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddRightText>
                <h1 className="font15 semiBold">
                  APH Medik Ambulancias 24 / 7{" "}
                </h1>{" "}
                <br />
                <h1 className="font40 extraBold">
                  {" "}
                  ¿Necesitas una Ambulancia ?
                </h1>{" "}
                <p className="font15">
                  APH Medik cuenta cuenta con servicio de traslado de pacientes
                  y asistencia médica para emergencias 24 / 7, nuestros
                  profesionales te atenderan de acuerdo a tu requerimiento.{" "}
                </p>{" "}
                <ButtonsRow
                  className="flexNullCenter"
                  style={{ margin: "30px 0" }}
                >
                  <div style={{ width: "190px" }}>
                    <Link
                      activeClass="false"
                      className="whiteColor"
                      style={{ padding: "10px 0px" }}
                      to="contact"
                      smooth={true}
                      offset={-80}
                      onClick={eventSave("Site_Contact_NecesitasAmbulancia")}
                    >
                      <FullButton title="Contactanos" />
                    </Link>{" "}
                  </div>{" "}
                </ButtonsRow>{" "}
              </AddRightText>{" "}
              <AddLeftImage>
                <AddRightInner>
                  <div className="flexNullCenter">
                    <AddImgWrapp1 className="flexCenter">
                      <img src={a1} alt="office" />
                    </AddImgWrapp1>{" "}
                    <AddImgWrapp2>
                      <img src={a2} alt="office" />
                    </AddImgWrapp2>{" "}
                  </div>{" "}
                  <div className="flexNullCenter">
                    <AddImgWrapp3>
                      <img src={a3} alt="office" />
                    </AddImgWrapp3>{" "}
                    <AddImgWrapp4>
                      <img src={a4} alt="office" />
                    </AddImgWrapp4>{" "}
                  </div>{" "}
                </AddRightInner>{" "}
              </AddLeftImage>{" "}
            </Advertising>{" "}
          </div>{" "}
        </div>{" "}
        <div className="lightBg">
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <h1 className="font15 semiBold">
                  APH Medik te lleva el médico a tu domicilio{" "}
                </h1>{" "}
                <br />
                <h1 className="font40 extraBold">
                  {" "}
                  ¿Necesitas un médico en casa ?
                </h1>{" "}
                <p className="font15">
                  APH Medik siempre al alcance de tu bienestar, agenda una cita
                  o llamanos, nuestro personal médico siempre estara disponible
                  para ti y toda tu familia.{" "}
                </p>{" "}
                <ButtonsRow
                  className="flexNullCenter"
                  style={{ margin: "30px 0" }}
                >
                  <div style={{ width: "190px" }}>
                    <Link
                      activeClass="false"
                      className="whiteColor"
                      style={{ padding: "10px 0px" }}
                      to="contact"
                      smooth={true}
                      offset={-80}
                      onClick={eventSave("Site_Contact_NecesitasMedico")}
                    >
                      <FullButton title="Contactanos" />
                    </Link>{" "}
                  </div>{" "}
                </ButtonsRow>{" "}
              </AddLeft>{" "}
              <AddRight>
                <AddRightInner>
                  <div className="flexNullCenter">
                    <AddImgWrapp1 className="flexCenter">
                      <img src={M1} alt="office" />
                    </AddImgWrapp1>{" "}
                    <AddImgWrapp2>
                      <img src={M2} alt="office" />
                    </AddImgWrapp2>{" "}
                  </div>{" "}
                  <div className="flexNullCenter">
                    <AddImgWrapp3>
                      <img src={M3} alt="office" />
                    </AddImgWrapp3>{" "}
                    <AddImgWrapp4>
                      <img src={M4} alt="office" />
                    </AddImgWrapp4>{" "}
                  </div>{" "}
                </AddRightInner>{" "}
              </AddRight>{" "}
            </Advertising>{" "}
          </div>{" "}
        </div>{" "}
        <div className="whiteBg">
          <div className="container">
            <HeaderInfo>
              <h1 className="font40 extraBold">
                {" "}
                APH Medik siempre al alcance de ti{" "}
              </h1>{" "}
              <p className="font13"></p>{" "}
            </HeaderInfo>{" "}
            <div className="row textCenter">
              <ImageListService />
            </div>{" "}
          </div>{" "}
        </div> 
      </div>{" "}
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  text-align: center;
  @media (max-width: 100%) {
    text-align: center;
  }
  @media (max-width: 860px) {
    margin-top: 50px;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: center;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    text-align: justify;
  }
  @media (max-width: 860px) {
    width: 90%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;

const AddLeftImage = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  left: -30px;
  @media (max-width: 860px) {
    width: 90%;
    position: relative;
    order: 1;
    top: -40px;
    left: 0px;
  }
`;

const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 90%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;

const AddRightText = styled.div`
  width: 50%;
  margin-left: auto;
  margin-right: 0;
  p {
    text-align: justify;
  }

  @media (max-width: 860px) {
    width: 90%;
    order: 2;
    margin-right: 0;
    margin-left: 0;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;

const AddImgWrapp = styled.div`
  width: 100%;
  padding-top:50px;
  padding-left:0px;
  img {
    width: 100%;
    height: auto;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;

const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
