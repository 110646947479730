import React, { useEffect, useRef, useState } from 'react';
import QRCode from 'qrcode.react';
import styled from "styled-components";
import { DecryptValue, EncryptValue } from '../utils/Encrypt';
import { saveAllUsers } from '../service/UserService';



const users = [
    "driver-G-01",
    "driver-G-02",
    "driver-G-03",
    "driver-G-04",
    "driver-G-05",
    "paramedic-G-01",
    "paramedic-G-02",
    "paramedic-G-03",
    "paramedic-G-04",
    "paramedic-G-05",
    "paramedic-G-06",
    "paramedic-G-07",
    "paramedic-G-08",
    "paramedic-G-09",
    "paramedic-G-010",
    "paramedic-G-011",
    "paramedic-G-012",
    "paramedic-G-013",
    "coordinator-G-01",
    "operational-chieft-G-01"
  ];


export default function GenerateQR() {

  const canvasRef = useRef(null);
  const [url, setUrl] = useState('');
  const [urlList, setUrlList] = useState([]);
  const qrContainerRef = useRef(null);


  useEffect(() => {

    const canvas = document.querySelector('canvas');
    if (canvas) {
      canvasRef.current = canvas;
    }
  }, []);

  const handleAddUrl = () => {
    if (url.trim() !== '') {
      setUrlList([...urlList, url]);
      setUrl('');
    }
  };

  const handleGenerateAllQR = () => {
    urlList.forEach(url => downloadQR(url));
  };

  const downloadQR = (url) => {
    const canvas = qrContainerRef.current.querySelector(`[data-url="${url}"] canvas`);
    const qrImage = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.download = `${url.replace(/[^\w\s]/gi, '-')}_qrcode.png`;
    link.href = qrImage;
    link.click();
  };

  const downloadQRCode = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const link = document.createElement('a');
      link.download = 'qrcode.png';
      link.href = canvas.toDataURL('image/png');
      link.click();
    } else {
      console.error('Canvas is null');
    }
  };

  const encrpytAll = () => {
    users.map(e=>{
        const en = EncryptValue(e)
        const dec = DecryptValue(en)
        console.log("decriiiiii: ",en,"--",dec)
        const uri = `https://aphmedik.com/users?id=${en}`
        urlList.push(uri)
    })
    setUrl(users);
  };


  return (
    <Wrapper id="ours">
              <input
        type="text"
        placeholder="Ingrese la URL"
        value={url}
        onChange={(e) => setUrl(e.target.value)}
      />
      <button onClick={handleAddUrl}>Agregar</button>
      <button onClick={handleGenerateAllQR}>Generar Todos QR</button>
      <button onClick={encrpytAll}>Encriptar lista default</button>
      <div ref={qrContainerRef}>
        {/* Render QR codes here */}
        {urlList.map((url, index) => (
          <div key={index} style={{ margin: '20px' }}>
            <h3>URL: {url}</h3>
            <div ref={canvasRef}>
            <QRCode data-url={url} value={url} />
            </div>
          
            <button  style={{ margin: '20px' }} onClick={() => downloadQRCode(url)}>Descargar QR</button>
          </div>
        ))}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const Advertising = styled.div`
  padding: 50px 0;
  margin: 0px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: center;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  p {
    text-align: justify;
  }

  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: center;

  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 10%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
