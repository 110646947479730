import React, {Component} from "react";
import styled from "styled-components";
import ServiceBox from "../../Elements/ServiceBox";
import ClientLogo01 from "../../../assets/img/clients/logo01.svg"
import ClientLogo02 from "../../../assets/img/clients/logo02.svg";
import ClientLogo03 from "../../../assets/img/clients/logo03.svg";



class RowEvent extends Component {
    state = {
        listitems: [{index:0,title:"Deportivos",img:ClientLogo01,body:"dsdsdssd"},
        {index:1,title:"Conciertos",img:ClientLogo02,body:"dsdsdssd"},
        {index:2,title:"Culturales/Religiosos",img:ClientLogo03,body:"dsdsdssd"},
        {index:3,title:"Otros",img:ClientLogo01,body:"dsdsdssd"}]
      };
      render() {
          return(
            <React.Fragment>
                 <ServiceBoxRow className="flex">
                 {this.state.listitems.map(val => (
                      <ServiceBoxWrapper key={val.index}>
                        <ServiceBox
                          icon={val.img}
                          title={val.title}
                          subtitle={val.body}
                        />
                      </ServiceBoxWrapper>
                  
            ))}
                 </ServiceBoxRow>
        
          </React.Fragment>
          );
      }
       
}
export default RowEvent;

const ServiceBoxRow = styled.div`
display: flex;
justify-content: space-between;
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;

const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;