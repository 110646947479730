import firebase from "firebase/compat/app"
import "firebase/compat/auth";
import "firebase/compat/analytics";
import "firebase/compat/firestore";
import { getFirestore } from 'firebase/firestore'


export const app = firebase.initializeApp({
  apiKey: "AIzaSyASqwcC_Yg96XMxi23ZgS25FR5LKpw7SF8",
  authDomain: "site-aphmedik.firebaseapp.com",
  projectId: "site-aphmedik",
  storageBucket: "site-aphmedik.appspot.com",
  messagingSenderId: "467017945297",
  appId: "1:467017945297:web:b754a93bd9fac8915035f0",
  measurementId: "G-CVJV3M88Q6"
});

export const dbFB = getFirestore(app)