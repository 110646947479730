import {BrowserRouter as Router, Route,Switch} from "react-router-dom";
import TopNavbar from "../components/Nav/TopNavbar";
import Landing from "../screens/Landing";
import Social from "../screens/Social";
import Users from "../screens/Users";
import GenerateQR from "../screens/GenerateQr";



const RoutePage = () =>{
    return(
        <Router>
            <Switch>
               <Route exact path="/">
                   <Landing/>
                </Route> 
                <Route exact path="/social">
                   <Social/>
                </Route> 
                <Route exact path="/users">
                   <Users/>
                </Route> 
                <Route exact path="/generate-qr">
                   <GenerateQR/>
                </Route> 
            </Switch>
        </Router>
    )
}

export default RoutePage;