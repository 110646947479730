import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import LogoImg from "../../assets/svg/Logo";
import Icon192 from "../../assets/img/Icon-192.png"

export default function Contact() {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <Wrapper>
      <div className="darkBg">
        <div className="container">
          <InnerWrapper
            className="flexSpaceCenter"
            style={{ padding: "30px 0" }}
          >
            <Link
              className="flexCenter animate pointer"
              to="home"
              smooth={true}
              offset={-80}
            >
             <img
          className="icon"
          src={Icon192}
          alt=""
          style={{ height: "55px", width: "55px", margin: "0px 0px" }}
        />
              <h1
                className="whiteColor font20 extraBoldTwoline"
                style={{ marginLeft: "10px" }}
              >
                APH Medik <br />
                <span className="font13 semiBold">
                  Servicio de Emergencia y Rescate{" "}
                </span>{" "}
              </h1>{" "}
            </Link>{" "}
            <StyleP className="whiteColor font13">
              {" "}
              ©{getCurrentYear()} -{" "}
              <span className=" font13"> APH Medik </span> All Right
              Reserved{" "}
            </StyleP>
           
          </InnerWrapper>{" "}
        </div>{" "}
      </div>{" "}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;
