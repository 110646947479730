
import {collection,query,where,getDocs,addDoc,doc} from "firebase/firestore" 
import { dbFB } from "../firebaseConfig"

const coll_users = collection(dbFB, "users")

const personas = [
    {  id_number: "01", event: { type_event: "concierto", short_desc: "concierto de salsa" }, type: "CONDUCTOR", id_type: "driver-G-01", name: "Carlos Andrade", full_names: "Cabrera Bazurto" },
    { id_number: "02", event: { type_event: "concierto", short_desc: "concierto de salsa" }, type: "CONDUCTOR", id_type: "driver-G-02", name: "Juan Pérez", full_names: "García Ramírez" },
    {  id_number: "03", event: { type_event: "concierto", short_desc: "concierto de salsa" }, type: "CONDUCTOR", id_type: "driver-G-03", name: "Luis Martínez", full_names: "Sánchez López" },
    {  id_number: "04", event: { type_event: "concierto", short_desc: "concierto de salsa" }, type: "CONDUCTOR", id_type: "driver-G-04", name: "Miguel Torres", full_names: "Jiménez Castillo" },
    { id_number: "05", event: { type_event: "concierto", short_desc: "concierto de salsa" }, type: "CONDUCTOR", id_type: "driver-G-05", name: "David Rojas", full_names: "Vargas Fernández" },
    {  id_number: "01", event: { type_event: "concierto", short_desc: "concierto de salsa" }, type: "PARAMÉDICO", id_type: "paramedic-G-01", name: "Ana López", full_names: "Gómez Herrera" },
    { id_number: "02", event: { type_event: "concierto", short_desc: "concierto de salsa" }, type: "PARAMÉDICO", id_type: "paramedic-G-02", name: "María González", full_names: "Rodríguez Morales" },
    {  id_number: "03", event: { type_event: "concierto", short_desc: "concierto de salsa" }, type: "PARAMÉDICO", id_type: "paramedic-G-03", name: "José Fernández", full_names: "Soto Núñez" },
    {  id_number: "04", event: { type_event: "concierto", short_desc: "concierto de salsa" }, type: "PARAMÉDICO", id_type: "paramedic-G-04", name: "Elena Ruiz", full_names: "Martín Castro" },
    {  id_number: "05", event: { type_event: "concierto", short_desc: "concierto de salsa" }, type: "PARAMÉDICO", id_type: "paramedic-G-05", name: "Pablo Ramírez", full_names: "Ortiz Flores" },
    {  id_number: "06", event: { type_event: "concierto", short_desc: "concierto de salsa" }, type: "PARAMÉDICO", id_type: "paramedic-G-06", name: "Laura Vargas", full_names: "Serrano Luna" },
    {  id_number: "07", event: { type_event: "concierto", short_desc: "concierto de salsa" }, type: "PARAMÉDICO", id_type: "paramedic-G-07", name: "Diego Torres", full_names: "Guerra Chávez" },
    { id_number: "08", event: { type_event: "concierto", short_desc: "concierto de salsa" }, type: "PARAMÉDICO", id_type: "paramedic-G-08", name: "Paula Muñoz", full_names: "Rivera Mendoza" },
    {  id_number: "09", event: { type_event: "concierto", short_desc: "concierto de salsa" }, type: "PARAMÉDICO", id_type: "paramedic-G-09", name: "Roberto Díaz", full_names: "Ramos Jiménez" },
    { id_number: "010", event: { type_event: "concierto", short_desc: "concierto de salsa" }, type: "PARAMÉDICO", id_type: "paramedic-G-010", name: "Carmen Castillo", full_names: "Lara Hernández" },
    { id_number: "011", event: { type_event: "concierto", short_desc: "concierto de salsa" }, type: "PARAMÉDICO", id_type: "paramedic-G-011", name: "Antonio Paredes", full_names: "Mora Aguilar" },
    {  id_number: "012", event: { type_event: "concierto", short_desc: "concierto de salsa" }, type: "PARAMÉDICO", id_type: "paramedic-G-012", name: "Lucía Ramos", full_names: "Guzmán Peña" },
    {  id_number: "013", event: { type_event: "concierto", short_desc: "concierto de salsa" }, type: "PARAMÉDICO", id_type: "paramedic-G-013", name: "Héctor Miranda", full_names: "Bautista García" },
    {  id_number: "01", event: { type_event: "concierto", short_desc: "concierto de salsa" }, type: "COORDINADOR", id_type: "coordinator-G-01", name: "Rosa Vargas", full_names: "Morales Serrano" },
    { id_number: "01", event: { type_event: "concierto", short_desc: "concierto de salsa" }, type: "JEFE OPERATIVO", id_type: "operational-chieft-G-01", name: "Pedro Herrera", full_names: "Reyes Villalobos" }
];


export const getUser = async (payload) =>{
    try{
        const ref = query(coll_users,  where('id_type', '==', payload))
        const snapshot = await getDocs(ref)
        let snaps = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }))

        if (snaps.length){
            return {error:false,result:snaps[0]}
        }else{
            return {error:true,result:[],msg:"Error al cargar la información"}
        }
    }catch(e){
        return {error:true,result:[],msg:"Error al cargar la información"}
    }
}

export const saveAllUsers = async () => {
    try {
        personas.forEach(async data => {
        console.log('ldataaaaaa: ', data)
        try {
          await addDoc(coll_users, data)
          console.log('Documento guardado en Firestore:', data)
        } catch (error) {
          console.error('Error al guardar documento en Firestore:', error)
        }
      })
  
      // callback({ result: data, error: false, msg: 'Creación de usuario exitosa' })
    } catch (e) {
      console.log('Documento error:', e)
  
      // callback({ result: null, error: true, msg: e })
    }
  }